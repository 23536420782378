import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Areas/SuccessBox';
import AreasTable from 'components/Web_User_Interface/1080p_Series/Alarm/Areas/areasTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Areas",
  "path": "/Web_User_Interface/1080p_Series/Alarm/Areas/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts it´s sensitivity to set a threshold for trigger events. Once the motion detection in an area is activated, its sensitivity can be adjusted from very insensitive (value=0) to very sensitive (value=100).",
  "image": "./WebUI_1080p_SearchThumb_Alarm_Alarm_Areas.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Areas.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Areas' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts it´s sensitivity to set a threshold for trigger events. Once the motion detection in an area is activated, its sensitivity can be adjusted from very insensitive (value=0) to very sensitive (value=100).' image='/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Areas.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Areas.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Alarm/Bereiche/' locationFR='/fr/Web_User_Interface/1080p_Series/Alarm/Areas/' crumbLabel="Alarm Areas" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - adjusts it´s sensitivity to set a threshold for trigger events.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f287ed9100b6237cd15b141920f77450/8dd93/1080p_Settings_Alarm_Areas.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADCElEQVQoz32Q209SARzHz/9RD1QOmBHgAQyQmyngpbY0V4+02rqLoAZauhIlgyAIDkeaJhlaJBHHmDpLl625+VCrZm72kh5NWzcUAeFwbs1LD22tzz4Pv9/D7/vdfkBz/4Rp5JMBmdEjM/roTN328GfVIx/1yIwh+r4e2dQYfX8x9GZbw+Ab4HVsONLcirS0PWu1DF+3jlhuvuy0T9kdkzbXeOet0Q7ryPWOe7eGXY5ph3Xyrm/6R2IjlSNTOJXIEsCED+rctdvNZHcf4D0QCAYKZUOykqisKCyRhOXykBiMSIQmtV2nDekU/ks1QRzDaJomSCqL48ALv9/D54UrKp6WV/bJlM+OVIU11Y81FYOassdqzVNtWVRbce0YrD8xpCsP1unCmY1MKpvDSZKmKWDcD8O8/F657Mkh5YBC5i8UB8TiflVxsLQ8VFoaVCpjWrX9uMOsi9SeiDWeiqRTGzRNUxSFEwTwHIYtDEZbXp4jnz1YIu8WSwLiwgGlolsqhUWigFSCaGQtle31NT1XqiHnhYdYFkvnCJwgaZoGxmC4ncG4wWJ2MJl+kBNSCBFt0ZBGCoEFPqGor1jVI5e7RCobKHNyheGqoxvJZI4gKYoiCGLreM/eG2yWlcl07WcNKguGSoV9B0UePq+/WBXSaiBQAAn4bi7Hlrf3vrYkl8mQNE2SFEmSwKtwuPfsGc/hSts+hr+A3clmebn5I0p+v4gTUCj8RdI7fL4XBB2c/V3VVWOW1mw6vZ7BcHKzHJiKxR61WYOnTwZEbBv3gJ3HvS8AI0J+rxD0yaW95SU9alWXqshTKHpw/two5MEyOw8jKApYmJtNf/2c/DKfWFqMo+gqiqZRdHUB/YWi8UV0bXkp+XUlsbK8uri4/v1bMh5fz+LxFIatrlGZDLA0O00vjFFr8/RWIP1fMIKcXEi8nVv+icSS7z4ADrvd5fG5XO7bTqfb7fbuAO3g9f4lBMG+TaEuGIJhwGAw1hnqa+uMxvoGk8nUeHlTk9nctMXVf9HU1GQ2mxsaGn4DoanxB2PEpqQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f287ed9100b6237cd15b141920f77450/e4706/1080p_Settings_Alarm_Areas.avif 230w", "/en/static/f287ed9100b6237cd15b141920f77450/d1af7/1080p_Settings_Alarm_Areas.avif 460w", "/en/static/f287ed9100b6237cd15b141920f77450/7f308/1080p_Settings_Alarm_Areas.avif 920w", "/en/static/f287ed9100b6237cd15b141920f77450/19805/1080p_Settings_Alarm_Areas.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f287ed9100b6237cd15b141920f77450/a0b58/1080p_Settings_Alarm_Areas.webp 230w", "/en/static/f287ed9100b6237cd15b141920f77450/bc10c/1080p_Settings_Alarm_Areas.webp 460w", "/en/static/f287ed9100b6237cd15b141920f77450/966d8/1080p_Settings_Alarm_Areas.webp 920w", "/en/static/f287ed9100b6237cd15b141920f77450/61eb3/1080p_Settings_Alarm_Areas.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f287ed9100b6237cd15b141920f77450/81c8e/1080p_Settings_Alarm_Areas.png 230w", "/en/static/f287ed9100b6237cd15b141920f77450/08a84/1080p_Settings_Alarm_Areas.png 460w", "/en/static/f287ed9100b6237cd15b141920f77450/c0255/1080p_Settings_Alarm_Areas.png 920w", "/en/static/f287ed9100b6237cd15b141920f77450/8dd93/1080p_Settings_Alarm_Areas.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f287ed9100b6237cd15b141920f77450/c0255/1080p_Settings_Alarm_Areas.png",
              "alt": "Web User Interface - 1080p Series - Alarm Areas",
              "title": "Web User Interface - 1080p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AreasTable mdxType="AreasTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "potential-errors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#potential-errors",
        "aria-label": "potential errors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Potential Errors`}</h3>
    <p>{`In case the camera's position is prone to rapid light changes, e.g. changes in sun intensity due to cloud movements, you might be confronted with a high number of false alarms. In this case please refer to Actions Menu for employing a PIR motion detector.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/76ee138f544adf8a5e36f69e4b897845/f680b/1080p_Settings_Alarm_Areas_wrong.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADa0lEQVQozyXR6U+bBQDA4TcmXjNLpmyCGgOzKzoow7GtMKHQlq3QcrVc5WhpoRyFcpSsBeoo0DqowBgM2UBoOdwNyIQ66CCMmCZuRuYSNVtiZmL8btTFD34w+Znov/A8wpdrQVaWl9nZ3mbp5jXm/VPcvDbHresL7ITDPPz+MVubmzz9+Rd++PEJX99/QDgcJhRaJ7S5xd3tbe6ENgiur7P76BGCrb0Fs8mIwVxGcb6ayuJc3B+2U2XQotNpsDvb8Q2eYyowQ2B+nslPJxkdG2FoqJ/h8z4uTU4Q3LjLzNwsiyuLCKP+cbpco5Ra1yktbuDjs83cmr+Et6+DupoKWtqtuHoc3Fi5wtXVJfqH+nB3m+ly5lNtUWIw5JKTJUelUhDcfYjQPeChxRYgT/8rRVobA2etuDotdDiqGbAaWVSrcFnNrN7/ip/++JNyQwbKjAOoNLGkpb/N0WQRItE+DiceJPTtLkKvz4vbtUxD6zMKchvoddbh6LByxl7JsNXAg1w1451tBL/7hifP/qK4Us5x6ZucVCSSqkzgaHIc0YciOCyJxn/BhzA162d05DKXb4Qw1dTR1VbP+HkvzjYLslQptvpafAMeguF7PP7td9T5MiTxEUhTRcRJ3iI+/g1iRK9SqJbjH/Ii+D4J0Nhow9Rqp9RoobnOhNvZjCxDxnMvvcj+aBEOzxBjny3w9O9/MJgy0MojMWjiyJPH8EHSQeLFESQmiv83bHL0UFikR6Y4TX6xAVuNkYLsDGJjxeSlSSg8JaWrtRZPt52d8D3ydOkoVGJ07mzsTSpKFPG8FrmXiAOvMBWYRDA3OdDkaJGmytGWGGmsKkGaFE9Wego9BgXOwhPYtcdoUCVQlR5LtU71X8yJnjSqqk5i1BznyJF3idq7h6JMGUJtiwt9RR0dvnn05mYsZTok772DKSeN1iIZ1apjWLKl1GtSqEiLpSRLTnVuBmZ1EqXKBAqUSXjt5fSdEeF1FyDUNHVx+lQe5dVOVEoVOenJpEgOYch8n8qUaPTSGMqkMZQkRqGV7MeUmYAk6mX2vCAQue95ksSvo88W43II+D5KR7j9+QoTI8NcDUxz5/YXbKyusbW2ytr1WZZmxlgOjLM0Pcbi1AWuXOzHP9zLoLebjs5Oznm6mZsYZPriIB63i4U5P/8CQswf2tEUhZUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ee138f544adf8a5e36f69e4b897845/e4706/1080p_Settings_Alarm_Areas_wrong.avif 230w", "/en/static/76ee138f544adf8a5e36f69e4b897845/d1af7/1080p_Settings_Alarm_Areas_wrong.avif 460w", "/en/static/76ee138f544adf8a5e36f69e4b897845/7f308/1080p_Settings_Alarm_Areas_wrong.avif 920w", "/en/static/76ee138f544adf8a5e36f69e4b897845/6a1a1/1080p_Settings_Alarm_Areas_wrong.avif 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/76ee138f544adf8a5e36f69e4b897845/a0b58/1080p_Settings_Alarm_Areas_wrong.webp 230w", "/en/static/76ee138f544adf8a5e36f69e4b897845/bc10c/1080p_Settings_Alarm_Areas_wrong.webp 460w", "/en/static/76ee138f544adf8a5e36f69e4b897845/966d8/1080p_Settings_Alarm_Areas_wrong.webp 920w", "/en/static/76ee138f544adf8a5e36f69e4b897845/17ad2/1080p_Settings_Alarm_Areas_wrong.webp 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ee138f544adf8a5e36f69e4b897845/81c8e/1080p_Settings_Alarm_Areas_wrong.png 230w", "/en/static/76ee138f544adf8a5e36f69e4b897845/08a84/1080p_Settings_Alarm_Areas_wrong.png 460w", "/en/static/76ee138f544adf8a5e36f69e4b897845/c0255/1080p_Settings_Alarm_Areas_wrong.png 920w", "/en/static/76ee138f544adf8a5e36f69e4b897845/f680b/1080p_Settings_Alarm_Areas_wrong.png 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/76ee138f544adf8a5e36f69e4b897845/c0255/1080p_Settings_Alarm_Areas_wrong.png",
              "alt": "Web User Interface - 1080p Series - Alarm Areas",
              "title": "Web User Interface - 1080p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Many small Alarm Areas distributed over the Frame`}</strong></p>
    <p>{`The smaller an area, the bigger (in percent) a change in the image will be. Small areas are a lot more sensitive and should be used over parts of the image, where you never want to miss someone walking through. But they also lead to a large number of false alerts und should be used sparsely. You will receive a lot of false alarm notifications in the example above.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/f680b/1080p_Settings_Alarm_Areas_correct.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADbElEQVQozyXQ7VMTdADA8d9dgcJ4kKfBNhgM2GDFeBg7noY4BATRKM4eNMnTE73ytBd56gXEQwJqKxzGgPGUSjAExjaJgDlBoUTTax1CktaVd113XXfl9Q98O68X3/ef+4rHdjvrZjO/9tr4seMzfOfaWDWf5+GnF/ilt5c/hkf42Wrlr1E7f345wtP+AX7r6+OJtYvH3d086enhJ6uVDYuF391uxGx1FQ6lEqdWw7gylol4BTez03GnqLAr5cxmZbBUkMf90lJ85eU8KC1hZVsR3xjz+bawgHvFJjb2vI6v2MT6+8cRK8fe5XZRPnf27sCRoeN2YR4PX6tkucyEO0vHTJ4Bz7YCVt+qZnX/XpYrSlgwZePd+hJuQxJTmSnYtWquxMpZb2lBzL9dw3KRiY0jB3BkvMySMQdvoR5vQSaebB03ioxM5+hZf2cfz+obcOnVXFZuZlgdwVB8CL2KMC5FBmIJ8uNRczNicf8brJWX8O/pE0ylp7LwXGQ04MlJ40ZOJnd2VXCreCuPDh3gn48amdJrsMkCGUiIYVAlxaaIwhIp4VKwPw9qDyHch9sZzDyKo6qVIV0F3pwMVspM3CwwMBQnx5WtZ7l0O+s1+/i7rp6xtGS6IjbRHxeGVRqCNSoIS3gAIwop39ceRDTumefV0H5eiRmhQXWMeb2WBWMuVxNVnPX341ygBI+pjKXKnTxrbOa6Qc1ofCAOjZTRhC0MybZgjQzCGryJtaYmxMmSa1QHd7I7rJt6ZS1zWVrGNUl0hocxopRiT5DhzX3+1MDTwwcZ1yXzhSKIuXQ5i/okJhOlmEMCaBeClfeOIj7YPkZVoIWdIVbqlUfwZKUwII/mcqychXQVHq2cuRQZXyVH41SG40xNxJUax7Qukq+1cqY0cmyyKC6+KBgvLkSc2jFBdejn1KidNKlPMKdPpSsqgklNPLNpKlxqBW5NLNdTlDgSoriWnIArNRGnRsFEsgx7khxvbjp2eQSu3ZWI43ljFIvzVEqucDLoTSajg+mTbMYRHYozwp/JsP+bCH2BMYnAFRNMn5+gTQjMQmALEIxKJXQIgd2Yj5gZ+I7BuhmGW2+xcNHJRo+VjZ4e1jo78Jnb+MHcju+TVnwXznK/rYm7Hzew2FjH9JkzzNd/yN32ZlbaW5g7fYp7fTb+A1NjElaGzGJ4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/e4706/1080p_Settings_Alarm_Areas_correct.avif 230w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/d1af7/1080p_Settings_Alarm_Areas_correct.avif 460w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/7f308/1080p_Settings_Alarm_Areas_correct.avif 920w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/6a1a1/1080p_Settings_Alarm_Areas_correct.avif 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/a0b58/1080p_Settings_Alarm_Areas_correct.webp 230w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/bc10c/1080p_Settings_Alarm_Areas_correct.webp 460w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/966d8/1080p_Settings_Alarm_Areas_correct.webp 920w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/17ad2/1080p_Settings_Alarm_Areas_correct.webp 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/81c8e/1080p_Settings_Alarm_Areas_correct.png 230w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/08a84/1080p_Settings_Alarm_Areas_correct.png 460w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/c0255/1080p_Settings_Alarm_Areas_correct.png 920w", "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/f680b/1080p_Settings_Alarm_Areas_correct.png 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/492b21abe34d1ce8a0e9962e4d9a82ae/c0255/1080p_Settings_Alarm_Areas_correct.png",
              "alt": "Web User Interface - 1080p Series - Alarm Areas",
              "title": "Web User Interface - 1080p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`One big Area - small Areas only where they are critical`}</strong></p>
    <p>{`A big area is - according to the explanation above - much more resistant against false alerts. A big area over the whole image will only trigger an alert when a person comes very close to the camera. You can use a slightly higher sensitivity to compensate.`}</p>
    <p>{`To prevent that you might loose actual alarm events, add a smaller area where it is critical - e.g. a window or your front door.`}</p>
    <p>{`The smaller this area is, the higher its sensitivity. To find the optimal setting, keep an eye on your camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Log/"
      }}>{`System Log`}</a>{`. If an area causes too many false alerts, make it bigger or decrease it´s sensitivity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      